import axios from '@/axios'

const route = 'profile';

export default {
    changePassword(formPassword) {
        return axios.post(route+'/change_password',formPassword)
    },
    
    update(User){
        return axios.post(route+'/update',User);
    }

}