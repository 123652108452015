<template>
  <v-container>
    <v-card-text>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
             <div class="title_body">
                  <span class="text_title text-h6">Trocar Senha</span>
                  <span class="btn_close"><v-btn icon dark @click="dialog = false" ><v-icon dark > mdi-close </v-icon></v-btn> </span>
             </div>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="form.current_password"
                    label="Senha atual*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="form.new_password"
                    label="Nova Senha*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    dense
                    v-model="form.new_password_confirmation"
                    label="Comfirma Senha*"
                    type="password"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
            <small>*campo obrigatório</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false"
              >Fechar</v-btn
            >
            <v-btn class="primary" text :loading="btn_save_password_loading" @click="changePassword()"> Salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-card class="mx-auto mt-3" max-width="500  " tile elevation="2">
        <v-card-text>

                <v-form class="px-3">
                  <v-row>
                     <v-col>
                      <input  style="display:none" type="file" @change="onFileSelected"  ref="photoInput">
                        <v-avatar style="cursor:pointer" class="" size="100" @click="$refs.photoInput.click()">
                          <img v-if="url != null" :src="url" >
                          <img v-else-if="editPhotoUser != null "  :src="'data:image/png;base64,'+editPhotoUser" >
                          <img v-else src="@/assets/default-avatar.png" >
                        </v-avatar>
                    </v-col>
                    <v-col>

                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                      dense
                        v-model="editedItem.name"
                        label="Nome"
                      ></v-text-field
                    ></v-col>
                     <v-col
                      ><v-text-field
                        dense
                        v-model="editedItem.email"
                        label="Email"
                        type="email"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      ><v-text-field
                        dense
                        v-model="editedItem.telephone"
                        label="Telefone"
                      ></v-text-field
                    ></v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field  dense label="Funçao" v-model="editedItem.occupation">
                        </v-text-field>
                    </v-col>
                  </v-row>

                </v-form>

                    <v-card-actions>
                       <v-btn color="primary" text @click="showChangePassword">Trocar Senha</v-btn>
                      <v-spacer></v-spacer>
                         <v-btn class="primary" :loading="btn_save_loading" @click="save">Salvar</v-btn>
                    </v-card-actions>

        </v-card-text>

    </v-card>
    <!-- message -->
    <v-dialog v-model="dialogResponse.dialog" max-width="550px">
      <v-card>
        <v-card-title class="text-h5">{{ dialogResponse.msg }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
            <v-btn color="" @click="dialogResponse.dialog = false">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-card-text>
  </v-container>
</template>



<script>
import { mapGetters, mapMutations } from "vuex";
import service from "./service";
export default {
  data: () => ({
    image:null,
    editPhotoUser:null,
    btn_save_loading:false,
    btn_save_password_loading:false,
    editedItem: {
      id: "",
      organization_id: "",
      name: "",
      occupation: "",
      email: "",
      telephone: "",
      password:"",
      password_confirmation:""

    },
    user: {},
    dialogResponse: {
      dialog: false,
      msg: "",
    },
    dialog: false,
    form: {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
    formDefault: {
      current_password: "",
      new_password: "",
      new_password_confirmation: "",
    },
  }),
  computed: {
    ...mapGetters("Login", ["getUser","getPhoto"]),

    url() {
      if (!this.image) return ;
      let url = URL.createObjectURL(this.image)
      return  url
    }
  },
  methods: {
    ...mapMutations("Login", ["setUser","setPhoto"]),
    onFileSelected(event){
        this.image = event.target.files[0]
    },
    changePassword() {
      this.btn_save_password_loading = true
      service
        .changePassword(this.form)
        .then(() => {
          this.dialog = false;
          this.$toast.success("Senha salva com sucesso !");
          this.form = Object.assign({},this.formDefault)
        })
        .catch((response) => {
          this.dialogResponse.dialog = true;
          this.dialogResponse.msg = response.response.data;
        }).finally(()=>{
          this.btn_save_password_loading = false
        })
    },
    save(){
      this.btn_save_loading = true
        let formData = new FormData()

        formData.append('id',this.editedItem.id)
        formData.append('email',this.editedItem.email )
        formData.append('name',this.editedItem.name )
        formData.append('occupation',this.editedItem.occupation )
        formData.append('telephone',this.editedItem.telephone )
        //se não alterou a imagem não envia
        if(this.image!=null){
          formData.append('photo',this.image)
        }

        service.update(formData).then((response)=>{
          //console.log(response.data);
          this.setUser(response.data.user)
          this.setPhoto(response.data.photo)
          this.$toast.success("Salvo com sucesso !");
         })
         .finally(()=>{
            this.btn_save_loading = false
         })
    },
    showChangePassword(){
      this.form = Object.assign({},this.formDefault)
      this.dialog = true
    }
  },
  created() {
    this.editedItem = Object.assign({},this.getUser) ;
    this.editPhotoUser = this.getPhoto
  },

};
</script>


<style>
</style>
